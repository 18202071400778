const module2Messages = {
  part1: {
    intro: {
      objectif1: 'Identifier les acteurs du dispositif CEE et comprendre leurs interactions'
    },
    tabs: {
      lesson1: 'Acteurs',
      lesson2: 'Écosystème N°1',
      lesson3: 'Écosystème N°2'
    },
    lesson: {
      title: 'L’écosystème des CEE',
      titleAnnotation: 'Le dispositif des CEE est une solution incontournable de financement des travaux de rénovation énergétique.<br>' +
        'Que vous soyez artisan ou entreprise du bâtiment, conseiller ou accompagnateur des professionnels de la rénovation énergétique, vous aurez besoin de bien connaître l’écosystème des CEE.',
      block1: {
        title: 'Les acteurs de l’écosystème CEE',
        actors: {
          title1: 'L\'administration française',
          title2: 'Les demandeurs de cee',
          title3: 'Les bénéficiaires / maîtres d’ouvrage',
          title4: 'les artisans et entreprises du bâtiment',
          pnceeLabel: 'Le <abbr title="Pôle National des Certificats d\'Économies d\'Énergie">PNCEE</abbr>',
          pnceeContent: 'administrateur des dossiers de demandes de CEE par le Ministère de la Transition énergétique.',
          obligeLabel: 'Les Obligés',
          obligeContent: 'contraints de produire des CEE par l’Etat ou de payer des amendes.',
          delegataireLabel: 'Les délégataires',
          delegataireContent: 'administrent des demandes de CEE pour le compte de certains obligés.',
          eligibleLabel: 'Les éligibles,',
          eligibleContent: 'comme les obligés, peuvent déposer des demandes de CEE en leur nom mais par contre ils n\'y sont pas contraints. Ce sont les collectivités territoriales et leurs établissements publiques, les sociétés d\'économie mixte, l\'ANAH et les bailleurs sociaux.',
          maitreOuvrageLabel: 'L’ensemble des ménages, syndicats de copropriétés, entreprises tertiaires',
          maitreOuvrageContent: 'qui commandent des travaux d’économies d’énergie et peuvent bénéficier de primes CEE.',
          proLabel: 'Les professionnels qualifiés',
          proContent: 'pour réaliser des travaux de rénovation énergétique produisant des économies d’énergie.'
        }
      },
      interactionBlock: {
        title: 'Les interactions entre les acteurs',
        infoLabel: 'Cas n°\xa0',
        info1: 'Le bénéficaire / maître d\'ouvrage au centre du dispositif',
        info2: 'L\'artisan ou l\'entreprise du bâtiment au centre du dispositif'
      },
      endBlock: 'Voyons si vous êtes en mesure d’identifier les rôles de chaque acteur de l’écosystème CEE'
    },
    activity: {
      title: 'Attribuez les rôles aux acteurs concernés',
      quizzTitle: 'rôle',
      intro: {
        title: 'Connaître les acteurs de l’écosystème CEE et leurs fonctions',
        rules: 'L’activité contient 6 affirmations. Pour chacune d’entre elles, <b>sélectionnez le ou les acteurs correspondants pour obtenir 1 point.</b>'
      },
      answers: {
        pncee: 'PNCEE',
        oblige: 'Obligés ou délégataires ou éligibles',
        pro: 'Artisans ou entreprises du bâtiment',
        mo: 'Maîtres d’ouvrage'
      },
      questions: {
        question1: '« Je délivre les CEE »',
        question2: '« Je suis demandeur de CEE »',
        question3: '« Je peux être bénéficiaire d’une prime CEE »',
        question4: '« Je réalise des économies d’énergie »',
        question5: '« Je peux porter le rôle actif et incitatif »',
        question6: '« Je constitue le dossier CEE »'
      },
      corrections: {
        correction1: '<b>Le <abbr title="Pôle National des Certificats d\'Économies d\'Énergie">PNCEE</abbr>,</b> sous tutelle du ministère de la Transition énergétique, <b>administre les demandes et délivre les CEE.</b>',
        correction2: '<b>Les demandeurs de CEE sont ceux qui déposent des demandes de CEE auprès du <abbr title="Pôle National des Certificats d\'Économies d\'Énergie">PNCEE</abbr>.</b>',
        correction3: '<b>Les bénéficiaires sont ceux qui reçoivent les primes et financent les travaux de rénovation énergétique.</b>',
        correction4: 'Les travaux de rénovation énergétique sont réalisés par les professionnels du bâtiment mais <b>ce sont les maîtres d’ouvrage qui réalisent des économies d’énergie.</b>',
        correction5: '<b>Le rôle actif et incitatif est porté par les demandeurs de CEE. Ils peuvent déléguer ce rôle aux artisans et entreprises du bâtiment par contrat. </b>',
        correction6: '<b>Les dossiers CEE peuvent être constitués par les bénéficaires / maîtres d’ouvrage ou les professionnels du bâtiment affiliés à un demandeur de CEE.</b> Le dossier est dans ce cas contrôlé par le demandeur de CEE avant d’être déposé auprès du PNCEE.'
      }
    }
  },
  part2: {
    intro: {
      objectif1: 'Identifier une opération éligible aux CEE et au Coup de pouce'
    },
    lesson: {
      title: 'Identifier une opération éligible',
      titleAnnotation: 'Pour rappel, les chantiers générant des CEE sont définis par des fiches d’opérations standardisées.<br>' +
        '<br>' +
        'Découvrez où trouver les fiches et comment les lire&nbsp;:',
      videoButton1: 'Comprendre les opérations CEE en vidéo',
      block1: {
        title: 'Les primes Coup de pouce, des CEE bonifiés',
        content: 'Certaines opérations CEE peuvent générer des primes Coup de pouce. Elles permettent de valoriser les CEE à un taux supérieur à condition de remplir des exigences supplémentaires\xa0:',
        videoButton: 'Comprendre les opérations Coup de Pouce'
      },
      block2: {
        title: 'Liens utiles',
        via: 'via le ',
        surLe: 'sur le ',
        links1: {
          title: 'Pour connaître les opérations CEE',
          content: 'La liste des fiches d’opérations standardisées est accessible\xa0:',
          link1: 'catalogue officiel',
          link1Content: ', sur le site du Ministère',
          link3: 'site de l’ATEE'
        },
        links2: {
          title: 'Pour connaître les opérations Coup de pouce',
          content1: 'Les opérations Coup de Pouce sont recensées sur le ',
          link1: 'site du Ministère',
          content2: '(dans la rubrique Certificats d’Économies d’Énergie).'
        },
        links3: {
          title: 'Pour connaître les signataires d’une Charte Coup de pouce',
          content1: 'La liste des signataires d’une opération Coup de pouce est disponible sur la page correspondante du ',
          link1: 'site du Ministère',
          content2: ' (à la section concernant les « offres disponibles »)'
        }
      }
    }
  },
  part3: {
    intro: {
      objectif1: 'Connaître les modalités de contrôle des demandes de CEE',
      objectif2: 'Identifier les documents requis lors d’une demande de CEE',
      objectif3: 'Distinguer les différentes étapes d’une opération CEE'
    },
    lesson1: {
      tab: 'contrôles',
      title: 'Les contrôles des opérations CEE',
      videoButton: 'Les contrôles des opérations CEE en vidéo',
      block1: {
        title: 'Les types de contrôle',
        tab1: {
          title: 'Contrôle du dossier',
          content1: 'Systématique',
          content2: 'Assuré par le demandeur CEE puis instruit par le <abbr title="Pôle National des Certificats d\'Économies d\'Énergie">PNCEE</abbr>'
        },
        tab2: {
          title: 'Contrôle de qualité des travaux de rénovation énergétique',
          content1: 'Pour certaines opérations\xa0:',
          content2: 'Contrôle sur chantier : assuré par un bureau de contrôle indépendant accrédité par le <abbr title="Comité français d\'accréditation">COFRAC</abbr>',
          content3: 'Contrôle par téléphone : assuré par le demandeur de CEE auprès du bénéficiaire',
          modalButton1: 'Voir les contrôles pour le résidentiel',
          modalTitle1: '',
          modalButton2: 'Voir les contrôle pour le tertiaire'
        }
      },
      block2: {
        title: 'Types et exemples d’anomalies',
        content: 'Des anomalies peuvent être relevées lors des contrôles. Selon leur importance, la prime sera soit annulée soit suspendue jusqu’à ce que les anomalies soient corrigées.',
        examples: {
          label: 'exemples',
          example1: {
            label: 'Prime annulée',
            title: 'Anomalie disqualifiante',
            content: 'Qualification manquante / Non respect des exigences de performance / Inéligibilité du bâtiment / etc.'
          },
          example2: {
            label: 'Prime repoussée',
            title: 'Anomalie à corriger',
            content: 'Erreur dans les documents / Document manquant / Défaut de mis en œuvre (s’il n’a pas de conséquence sur la performance) / etc.'
          }
        }
      },
      block3: {
        title: 'Les anomalies les plus courantes et comment les éviter',
        subTitle1: 'Non-conformité des chantiers',
        content1: 'Pour satisfaire aux exigences d’un contrôle qualité, <b>les travaux doivent respecter les niveaux de performance annoncés mais aussi les normes de mise en œuvre</b> inscrites dans les <abbr title="Documents Techniques Unifiés">DTU</abbr>.',
        subTitle2: 'Erreur dans les documents',
        content2: 'Si la moindre mention est manquante ou que des informations sont incohérentes entre les documents, le dossier est à corriger. Il convient donc de <b>bien valider l’exactitude des informations renseignées à chaque étape.</b>',
        subTitle3: 'Document manquant',
        content3: 'Les pièces justificatives sont nombreuses et dépendent des opérations valorisées. Cette complexité aboutit à de nombreux oublis dans les dossiers.<br><br>' +
          'Découvrez maintenant quels sont les documents indispensables à toute demande de CEE.',
        trainingLabel: 'savez-vous',
        trainingQuestion: 'Combien de chantiers audités doivent être mis en conformité\xa0?',
        trainingAnswer1: '8\xa0%',
        trainingAnswer2: '15\xa0%',
        trainingAnswer3: '20\xa0%'
      }
    },
    lesson2: {
      tab: 'documents',
      title: 'Les documents nécessaires au dépôt d’une demande CEE',
      intro: 'Voici les documents nécessaires <b>dans le cas où l\'artisan ou l\'entreprise du bâtiment gère le dossier CEE.</b>',
      stepLabel: 'Étape',
      block1: {
        subTitle: 'En amont des travaux',
        tab1: {
          title: 'Contrat de partenariat',
          head: 'atteste de <b>l’affiliation</b> d’un artisan ou d’une entreprise du bâtiment <b>à un demandeur</b>',
          warning: 'Pour qu’une prime CEE soit proposée, le contrat de partenariat doit être valide à cette date (signé par les deux parties et en cours de validité).',
          drawerHead: 'Contenu du contrat de partenariat',
          drawerContent: 'Le contrat de partenariat décrit :<br>' +
            '- le cadre et les principes de la collaboration entre l’entreprise du bâtiment et le demandeur (rôles, obligations…)<br>' +
            '- Les types de travaux concernés par le contrat et les fiches d’opération standardisées qui seront valorisées<br>' +
            '- les modalités d’attribution de l’aide financière et la détermination de son montant lorsqu’il s’agit d’une contribution financière<br>' +
            '- les modalités d’établissement des documents à fournir lors d’une demande de CEE<br>' +
            '- les modalités de réalisation des contrôles des opérations concernées<br>' +
            '- la durée du contrat, les modalités de sa résiliation et de sa prolongation.'
        },
        tab2: {
          title: 'Qualifications RGE',
          head: 'prouve l’aptitude d’une entreprise à réaliser des travaux de rénovation efficaces',
          warning: 'Une qualification RGE est <b>obligatoire pour toutes les opérations CEE en résidentiel.</b> Elle doit porter sur le domaine de travaux concerné et être valide au moment de la signature du devis.\n' +
            '<b>' +
            '<b>Le devis et le certificat RGE doivent comporter un SIRET identique.</b>'
        }
      },
      block2: {
        subTitle: 'Initiation d\'une opération CEE',
        tab1: {
          title: 'Cadre Contribution',
          head: 'représente l’engagement écrit d’un demandeur à apporter une aide financière pour des travaux et un bénéficiaire donnés',
          warning: 'Le Cadre Contribution matérialise le rôle actif, incitatif et antérieur du demandeur, <b>il doit donc intervenir avant la signature des devis.<br>' +
            '<br>' +
            'Pour les clients personnes physiques ou syndicats de copropriétaires,</b> il est toléré que l’engagement puisse être obtenu jusqu’à 14 jours après la date de signature des devis des travaux, en tout état de cause avant la date de début des travaux.',
          drawerHead: 'Contenu du Cadre Contribution',
          drawerContent: 'Le Cadre Contribution doit mentionner :<br>' +
            '- le fait qu’il soit pris dans le cadre du dispositif des certificats d’économies d’énergie<br>' +
            '- les opérations d’économies d’énergie pour lesquelles la contribution serait apportée<br>' +
            '- la nature de cette contribution (prime, bon d’achat, prêt bonifié, audit ou conseil personnalisé, produit ou service offert, etc.) et sa valeur financière<br>' +
            '- la date d\'émission de l\'offre<br>' +
            '- l’identité du bénéficiaire de l’opération d’économies d’énergie<br>' +
            '- la raison sociale de l\'entreprise du bâtiment lorsqu\'elle avance et perçoit la prime'
        },
        tab2: {
          title: 'Devis ou bon de commande',
          head: 'réalisé sur la base de la visite technique',
          warning: '<b>La date de signature du devis correspond à la date d’engagement de l’opération.</b><br>' +
            '<br>' +
            'Pour des travaux d\'isolation, <b>un délai de 7 jours francs doit être respecté avant le démarrage des travaux.</b>',
          drawerHead: 'Contenu du devis',
          drawerContent: 'Pour être complet le devis doit faire figurer :<br>' +
            '- Numéro du devis ou bon de commande avec date d’émission<br>' +
            '- Identité de la société (nom, raison sociale, statut juridique, N°SIRET, coordonnées et adresse)<br>' +
            '- Qualification RGE avec mention du N° du certificat unique de RGE<br>' +
            '- Nature des travaux prévus et caractéristiques du matériel à mettre en œuvre répondant aux conditions de délivrance de la fiche d’opération standardisée<br>' +
            '- Date de la visite technique préalable<br>' +
            '- Identité du particulier (nom, prénom, adresse du chantier)<br>' +
            '- Montant du devis (avec déduction du montant de la prime CEE, si applicable)<br>' +
            '- Date et signature du particulier et du représentant de la société<br>' +
            '- Mentions obligatoires (décompte détaillé de chaque prestation et produit, prix unitaire fourniture et main d\'œuvre, montant total HT...)'
        }
      },
      block3: {
        subTitle1: 'Après les travaux',
        subTitle2: 'Autres justificatifs',
        content2: 'Des justificatifs supplémentaires peuvent être nécessaires <b>selon les fiches d’opérations standardisées utilisées</b> (note de dimensionnement, photos avant/après, etc.).',
        tab1: {
          title: 'Facture',
          head: 'constitue une preuve de la réalisation des travaux',
          warning: '<b>La date d’émission de la facture correspond à la date d’achèvement de l’opération</b>',
          drawerHead: 'Contenu de la facture',
          drawerContent: 'Pour être complète la facture doit faire figurer :<br>' +
            '- Numéro ou référence unique de la facture <br>' +
            '- Identité du particulier (nom, prénom, adresse du chantier)<br>' +
            '- Identité de votre société (nom, raison sociale, statut juridique, N°SIRET, coordonnées et adresse)<br>' +
            '- Nature des travaux réalisés et les caractéristiques du matériel mis en œuvre répondant aux conditions de délivrance de la fiche d’opération standardisée<br>' +
            '- Date de la visite technique préalable<br>' +
            '- Qualification RGE avec mention du N° du certificat unique de RGE<br>' +
            '- Date d’émission de la facture<br>' +
            '- Date et signature du particulier et du représentant de la société<br>' +
            '- Mentions obligatoires (numéro du bon de commande, date d\'échéance du règlement, pénalités en cas de retard...)'
        },
        tab2: {
          title: 'Attestation sur l’honneur',
          head: 'fait office de preuve de livraison des travaux, de fin du chantier.',
          warning: '<b>Aucune mention ne doit être ajoutée ou retirée</b> du modèle fourni par le demandeur CEE',
          drawerHead: 'Contenu de l’Attestation sur l’Honneur',
          drawerContent: 'L’attestation sur l’honneur est composée :<br>' +
            '- d’une partie A relative aux travaux réalisés, précisant l’opération concernée et ses caractéristiques techniques, identifiant la date d’engagement de l’opération ainsi que l’adresse des travaux<br>' +
            '- d’une partie B relative au bénéficiaire de l’opération, votre client, précisant son identité et attestant du rôle actif, incitatif et antérieur du fournisseur d’énergie/délégataire<br>' +
            '- d’une partie C relative au professionnel, précisant votre identité et l’exactitude des informations transmises.'
        }
      },
      block4: {
        title: 'Points de vigilance',
        content: '<b>Les informations doivent être cohérentes entre les différents documents</b> (descriptif des travaux réalisés, nom des bénéficiaires, N° de SIRET…)<br>' +
          '<br>' +
          '<b>Les dates d’édition des documents doivent être exactes</b><br>' +
          '<br>' +
          '<b>Le devis et facture doivent être datés et signés par le particulier et le représentant de la société</b><br>' +
          '<br>' +
          '<b>Le délai maximum demandé par l’obligé</b> pour transmettre l’ensemble des pièces <b>doit être respecté.</b> Il est précisé dans le contrat de partenariat.',
        documentButton: 'Voir un exemple de dossier CEE'
      }
    },
    lesson3: {
      tab: 'étapes',
      title: 'Les étapes d’une opération CEE',
      titleAnnotation: '<b>Les étapes d’une opération CEE différent selon le modèle d’affaire choisi</b> par l’artisan / entreprise du bâtiment.',
      endBlock: 'Voyons si vous êtes capable de déjouer les pièges à chaque étape d’une demande CEE',
      step1Name: 'En amont des travaux',
      step2Name: 'Initiation d\'une opération CEE',
      step3Name: 'Après les travaux',
      stepLabel1: 'Étape 1',
      stepLabel2: 'Étape 2',
      stepLabel3: 'Étape 3',
      case1: {
        title: 'le bénéficiaire / maître d\'ouvrage gère son dossier CEE',
        step1: '<b>Le maître d’ouvrage estime sa prime</b> auprès d’un demandeur CEE.',
        step2a: '<b>Il reçoit le cadre contribution</b> précisant l’objet des travaux, la nature et le montant de l’incitation.',
        step2b: '<b>Il contacte des professionnels pour réaliser une visite technique</b> préalable pour définir la faisabilité des travaux et <b>établir des devis.</b>',
        step2c: 'Il sélectionne un artisan / une entreprise du bâtiment, <b>vérifie la conformité du devis aux exigences de l\'opération CEE et le signe.</b>',
        step2d: 'Dans le cas d\'une opération CEE pour des travaux d\'isolation, attendre 7 jours après signature du devis avant de commencer les travaux.',
        step3a: '<b>À l’achèvement des travaux</b>, il reçoit la facture, la règle et fait signer l’attestation sur l’honneur au professionnel.',
        step3b: '<b>Le maître d’ouvrage constitue le dossier CEE complet</b> et le transmet au demandeur CEE.',
        step3c: 'Le demandeur CEE <b>contrôle le dossier et verse la prime si tout est conforme.</b>'
      },
      case2: {
        title: 'l\'artisan ou l\'entreprise du bâtiment gère le dossier CEE',
        step1: 'Le professionnel <b>réalise une visite technique préalable</b> pour vérifier la faisabilité des travaux, établir un devis et estimer la prime du client.',
        step2a: '<b>Le demandeur de CEE envoie le cadre contribution</b> précisant l’objet des travaux, la nature et le montant de l’incitation. <b>Le professionnel remet le devis à son client.</b><br>' +
          '<br>' +
          'S’il perçoit directement la prime, il doit la faire apparaitre sur son devis en déduction.',
        step2b: 'Dans le cas d\'une opération CEE pour des travaux d\'isolation, attendre 7 jours après signature du devis avant de commencer les travaux.',
        step3a: '<b>À l’achèvement des travaux</b>, il édite la facture, en demande le paiement et fait signer à son client l’attestation sur l’honneur.\n' +
          '<br><br>' +
          '<b>S’il perçoit directement la prime</b>, il doit la faire apparaitre sur sa facture en déduction.',
        step3b: '<b>Il constitue le dossier CEE complet et le transmet à son partenaire CEE.</b>',
        step3c: 'Le partenaire CEE <b>contrôle le dossier et verse la prime si tout est conforme.</b>'
      }
    },
    activity: {
      title: 'Déjouez les pièges à chaque étape de la demande CEE',
      intro: {
        title: 'Identifier les règles et étapes à respecter lors d’une opération CEE',
        rules: '<b>Vous êtes un professionnel du bâtiment affilié à un délégataire CEE</b> et avez pour mission de suivre une opération CEE de A à Z. Prenez la bonne décision à chacune des 6 étapes\xa0!'
      },
      questions: {
        question1: {
          context: 'demande de devis',
          title: 'Vous recevez une demande de devis pour le remplacement d’une chaudière fioul.',
          question: 'Que faites-vous en premier\xa0?',
          answers: {
            answer1: 'J’estime le montant de la prime auprès de mon délégataire',
            answer2: 'Je planifie une visite technique chez le propriétaire',
            answer3: 'Je recueille les informations du propriétaire pour le Cadre contribution'
          },
          correction: '<b>Avant d’estimer le montant de la prime et de signer le cadre contribution, il est nécessaire de réaliser une visite technique sur site</b> afin de vérifier la faisabilité des travaux et de définir les solutions à mettre en œuvre.'
        },
        question2: {
          context: 'visite technique',
          title: 'Le propriétaire est intéressé pour réaliser également des travaux d’isolation.',
          question: 'Que faites-vous en premier\xa0?',
          answers: {
            answer1: 'Je réalise une nouvelle visite technique',
            answer2: 'J’édite le devis pour spécifier le montant et la nature exacte des travaux'
          },
          correction: 'Les points contrôlés lors de la visite technique sont propres à chaque opération, il est donc indispensable de réaliser une nouvelle visite technique. Ensuite, il sera possible d\'estimer la prime et demander le cadre contribution à votre partenaire CEE puis d\'éditer le devis spécifiant la nature exacte des travaux et leur montant.'
        },
        question3: {
          context: 'création de la demande',
          title: 'Le propriétaire a signé et vous a retourné le devis et le Cadre contribution. Avec ces documents, vous constituez le dossier auprès de votre délégataire.',
          question: 'Les travaux concernant deux fiches d’opération, combien de demandes déposez-vous\xa0?',
          answers: {
            answer1: 'Deux, une par fiche d’opération',
            answer2: 'Une seule, car les travaux seront réalisés conjointement'
          },
          correction: '<b>Il est nécessaire de déposer un dossier de demande CEE par opération.</b>'
        },
        question4: {
          context: 'début des travaux',
          title: 'Le délégataire a validé les demandes de prime\xa0!',
          question: 'Quand les travaux de remplacement de la chaudière pourront-ils commencer\xa0?',
          answers: {
            answer1: '7 jours après la signature du devis',
            answer2: 'immédiatement'
          },
          correction: 'Le dispositif CEE impose <b>un délai de sept jours francs entre la signature du devis et le début des travaux uniquement pour les opérations standardisées d\'isolation.</b> Le non-respect de ce délai entraine l’annulation de la prime CEE.'
        },
        question5: {
          context: 'fin des travaux',
          title: 'Les travaux sont livrés\xa0!\nVous présentez au propriétaire votre facture et votre attestation sur l’honneur de fin de travaux.',
          question: 'Que doit faire le bénéficiaire pour débloquer sa prime\xa0?',
          answers: {
            answer1: 'Rien, c’est à moi de valider une fois la facture réglée',
            answer2: 'Il signe l’attestation sur l’honneur et règle les travaux'
          },
          correction: '<b>L’attestation sur l’honneur doit impérativement être signée par le client et le professionnel ayant réalisé les travaux.</b> Ce document constitue, avec la facture, la preuve de la réalisation de l’opération CEE.'
        },
        question6: {
          context: 'réception de la prime',
          title: 'Vous avez finalisé les dossiers auprès du délégataire mais un contrôle sur site a soulevé des anomalies alors que la prime CEE avait déjà été versée…',
          question: 'Quelles sont les conséquences si vous ne corrigez pas les anomalies\xa0?',
          answers: {
            answer1: 'Je dois rembourser la prime à mon partenaire CEE',
            answer2: 'Mon client doit rendre la prime CEE'
          },
          correction: 'En cas d\'anomalies non corrigées, le professionnel doit rembourser son partenaire CEE. Il risque aussi le retrait de sa qualification RGE et la rupture de son contrat de partenariat.'
        }
      }
    }
  }
}

export default module2Messages
